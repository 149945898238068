import React from "react"
import { Link } from "gatsby"
const DescriptionEbook = ({ data }) => {
  return (
    <section className="section-descrition-ebook">
      <section className="grid-descripiton-politicas">
        <section>
          <section className={data.class}>
            {data.menu.map(item => (
              <Link to={item.href} className="title-menu" key={item}>
                {item.text}
              </Link>
            ))}
          </section>
          <div />
        </section>

        <section className="description-politicas">
          <p id={data.intro} className="title-politicas">
            {data.title}
          </p>
          <div className="text-politicas">{data.textbody} </div>
        </section>
      </section>
    </section>
  )
}

export default DescriptionEbook
