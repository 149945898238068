import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

//variable de entorno
// eslint-disable-next-line no-undef
const GATSBY_ROOT_URL = process.env.GATSBY_ROOT_URL

const useStyles = makeStyles(() => ({
  navbarNotFixed: {
    background: "#262626",
    transition: "all 0.3s ease-out",
  },
  navbarFixed: {
    background: "#262626",
    position: "fixed",
    transition: "all 0.3s ease-out",
  },
  logoTicker: {
    backgroundImage: `url(${GATSBY_ROOT_URL}/Ticker.svg)`,
    height: "80px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  logoScore: {
    backgroundImage: `url(${GATSBY_ROOT_URL}/Score.svg)`,
    height: "80px",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  root: {
    background: "#262626",
    position: "absolute",
    transition: "all 0.3s ease-out",
  },
}))

//Boton Hamburguesa
const NavBar = props => {
  const classes = useStyles()
  const [posScroll, setposScroll] = useState(0)

  const handleScroll = () => {
    let lastScrollY = window.scrollY
    setposScroll(lastScrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [posScroll])

  return (
    <div className="section-header">
      <header
        className={`header ${
          posScroll > 1
            ? props.windowsWidth
              ? classes.navbarFixed
              : !props.bannerCTA
              ? classes.navbarFixed
              : classes.navbarNotFixed
            : classes.root
        }`}
      >
        <div className="grid-header">
          <Link to={`/ticker`} className="logo">
            <div className={props.logo ? classes.logoScore : classes.logoTicker}></div>
          </Link>
          <br />
        </div>
      </header>
    </div>
  )
}
export default NavBar
